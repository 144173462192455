<template>
	<div class="live" :style="pageStyle" v-if="showPage">
		<div class="nav">
			<nav-row />
		</div>
		<div class="videobox">
			<div class="titlecont">
				<em>{{dataSource.title}}</em>
				<time>{{getDate(dataSource.time_start,dataSource.time_end)}}</time>
			</div>
			<div class="video">
				<div v-if="show" id="ppt" ></div>
				<div v-if="show" id="player" ></div>
				<video-box v-else :videoData="video_data" ref="videoBoxIndex"/>
			</div>
			<div class="bottom-row">
				<span class="replay">
					{{getStatus(dataSource.live_status)}}
					<em v-if="getStatus(dataSource.live_status) == '距离直播开始还有'">
							<countDown :startTime="dataSource.time_start" />
						</em>
				</span>
				<span class="views share" @mouseenter="shareQRcode(true)"  @mouseleave="shareQRcode(false)">
					<i class="icon share"></i>
					<em class="num">分享</em>
					<ul v-if="showShare">
						<span></span>
						<li @click="shareToWB">
							<em>分享到微博</em>
							<i class="icon" :style="getIcon('sina')"></i>
						</li>
						<li>
							<em>分享到微信</em>
							<img class="qrcode" :src="getBGIcon(dataSource.share_qrcode)" />
						</li>
					</ul>
				</span>
				<span class="views" ref="handclick" @click.stop="upvotesFn(dataSource.guid)">
					<i class="icon hand"></i>
					<em class="num">{{initmoney(hand_num)}}</em>
				</span>
				<span class="views" >
					<i class="icon eyes"></i>
					<em class="num" style="color:rgba(255,255,255,0.8)">{{initmoney(eyes_num)}}</em>
				</span>
			</div>
		</div>
		<div class="last-row clearfix">
			<div class="left fl">
				<h3>直播介绍
					<span class="time">{{getDate(dataSource.time_start,dataSource.time_end)}}</span>
				</h3>
				<div class="title_phone">{{dataSource.title}}</div>
				<div class="author-row">
					<span v-if="getBGIcon(dataSource.company_image) != ''" class="icon" :style="{backgroundImage:'url('+getBGIcon(dataSource.company_image)+')'}"></span>
					<em>{{dataSource.company_name}}</em>
				</div>
				<div class="com-row" v-html="dataSource.description">
				</div>
			</div>
			<div class="right fr">
				<div class="border-row"></div>
				<h3>相关资讯</h3>
				<ul class="listbox">
					<li v-if="showNone" class="shownone">
						暂无相关资讯
					</li>
					<li v-else class="clearfix" v-for="(item,index) in infoList" :key="index" @click="toArticle(item.short_url)">
						<img class="fl" :src="getBGIcon(item.thumb_image)" alt="">
						<div class="fr">
							<h5 class="title-cont">{{item.title}}</h5>
							<span>{{setDate(item.time_updated)}}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import videoBox from '@/components/videoBox'
	import countDown from "@/components/countdown";
	import navRow from '@/components/layout/navRow'
	import {str2time, getQueryVariable,initmoney} from "../../utils";
	import wx from 'weixin-js-sdk'

	export default {
		name: "live",
		components:{
			videoBox,countDown,navRow
		},
		computed: {
			pageStyle() {
				let bg = '';
				let str = this.getBGIcon(this.dataSource.page_back_image);
				if (str == ''){
					bg = 'background-image: url('+require('@/assets/images/h5bg.png')+')'
				}else{
					bg = 'background-image: url('+str+')'
				}
				return bg;
			}
		},
		data(){
			return{
				video_data:null,
				showShare:false,
				dataSource: '',
				infoList: [],
				showNone: false,
				handSrc: '',
				handclick: false,
				lock:false,
				show:false,
				color:'rgba(255, 255, 255, 0.8)',
				showPage:false,
				hand_num:0, //number_of_upvotes
				eyes_num:0, //dataSource.number_of_reads
				timer: null
			}
		},
		watch:{
			dataSource(val){
				this.wxfn()
				document.title = val.title;
				var i = document.getElementsByTagName("meta");
				i[0]["content"] = val.share_description;
				this.changeTitle(val);
				return val;
			}
		},
		created() {
			let _this = this;
			_this.getLive();

		},
		beforeDestroy() {
			clearInterval(this.timer);
			this.timer = null;
		},
		methods:{
			changeTitle(val){
				//利用iframe的onload事件刷新页面
				document.title = val.title;
				var i = document.getElementsByTagName("meta");
				i[0]["content"] = val.share_description;
				var iframe = document.createElement('iframe');
				iframe.style.visibility = 'hidden';
				iframe.style.width = '1px';
				iframe.style.height = '1px';
				iframe.onload = function () {
					setTimeout(function () {
						document.body.removeChild(iframe);
					}, 0);
				};
				document.body.appendChild(iframe);
			},
			refreshNum(){
				let _this = this;
				let key =  getQueryVariable('videokey')
				_this.$api.liveUrl({
					url: key,
					data:{},
				}).then((ok)=> {
					_this.hand_num = ok.data.number_of_upvotes;
					_this.eyes_num = ok.data.number_of_reads;
				});
			},
			initmoney(val){
				return initmoney(val)
			},
			setDate(val){
				val = str2time(val,'.')
				let arr = val.split(' ');
				let arr2 = arr[0].toString().split('.');
				if (arr2[1].length < 1 && parseInt(arr2[1])<10){
					arr2[1] = '0'+arr2[1];
				}
				if (arr2[2].length < 1 && parseInt(arr2[2])<10){
					arr2[2] = '0'+arr2[2];
				}
				return arr2[1]+'月'+arr2[2]+'日'
			},
			getLiveData(){
				let _this = this;
				let userId = "";
				if (_this.dataSource.channel_detail.userCategory){
					userId = _this.dataSource.channel_detail.userCategory.userId
				}else{
					userId = "";
				}
				var liveSdk = new window.PolyvLiveSdk({
					channelId: _this.dataSource.channel_detail.channelId,
					sign: _this.dataSource.channel_token.liveSign, // 频道验证签名
					timestamp: _this.dataSource.channel_token.timestamp, // 毫秒级时间戳
					appId: 'fkzfp40q2f', //polyv 后台的appId
					user: {
						userId: userId,
						userName: _this.dataSource.channel_detail.name || '',
						pic: _this.dataSource.channel_detail.coverImg || ''
					}
				});
				// 监听频道信息并初始化播放器
				liveSdk.on(window.PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, () => {
					liveSdk.setupPlayer({
						pptEl: '#ppt',
						el: '#player',
						type: 'auto',
						autoplay: false,
						audioMode: false,
						width: '100%',
						height: '100%',
						pptWidth: '100%',
						pptHeight: '100%',
						controllerPosition: 'ppt',
						controller: true,
						pptNav: true,
						pptPlaceholder:true,
						// 回放模式需要fileId、url、sessionId
						fileId: undefined,
						url: undefined,
						sessionId: undefined
					});
				});
			},
			addUpvotes(guid){
				let  _this = this;
				_this.$api.addUpvotes({
					guid:guid,
					data:{
						type: 'add'
					},
				}).then((ok)=>{
					if (ok.result == 'ok'){
						let str = require('@/assets/images/icons/hand_l.png');
						_this.$refs.handclick.childNodes[0].style.backgroundImage = 'url("'+str+'")';
						_this.$refs.handclick.childNodes[1].style.color = 'rgb(234, 113, 20)'
						_this.hand_num += 1;
						_this.handclick = true;
						_this.lock = false;
					}
				});
			},
			delUpvotes(guid){
				let  _this = this;
				_this.$api.delUpvotes({
					guid:guid,
					data:{
						type: 'add'
					},
				}).then((ok)=>{
					if (ok.result == 'ok'){
						let str = require('@/assets/images/icons/hand.png');
						_this.$refs.handclick.childNodes[0].style.backgroundImage = 'url("'+str+'")';
						_this.$refs.handclick.childNodes[1].style.color = 'rgba(255, 255, 255, 0.8)';
						if (_this.hand_num<=0){
							_this.hand_num = 0;
						}else{
							_this.hand_num -= 1;
						}
						_this.handclick = false;
						_this.lock = false;
					}
				});
			},
			upvotesFn(guid){
				if (this.lock)return false;
				this.lock = true;
				let _this = this;
				if(_this.handclick){
					_this.delUpvotes(guid)
				}else{
					_this.addUpvotes(guid)
				}
			},
			getStatus(status){
				let num = 0;
				switch (status) {
					case 'live_teaser':
						num = '直播预告'
						break;
					case 'living':
						num = '直播中'
						break;
					case 'coming_soon':
						num = '距离直播开始还有';
						break;
					case 'generate_replay':
						num = '回放生成中'
						break;
					case 'live_replay':
						num = '回放'
						break;
				}
				return num;
			},
			shareQRcode(boolean){
				this.showShare = boolean;
			},
			shareToWB(){
				let _this = this;
				window.sharetitle = this.dataSource.share_title;
				window.shareUrl = _this.getBGIcon(_this.dataSource.share_image);
				(function(s, d, e) {
					var f = 'http://v.t.sina.com.cn/share/share.php?',
						u = window.location.href,
						p = ['url=', e(u), '&title=',e(window.sharetitle), '&pic=', e(window.shareUrl)].join('');

					function a() {
						if (!window.open([f, p].join(''), 'mb')){
							alert('分享失败')
						}
					}
					if (/Firefox/.test(navigator.userAgent)) {
						_this.showShare = !_this.showShare;
						setTimeout(a, 0)
					} else {
						a()
					}
				})(screen, document, encodeURIComponent);
			},
			isWeixin(){
				let ua = window.navigator.userAgent.toLowerCase()
				if (ua.indexOf('micromessenger') > -1) {
					return true
				} else {
					return false
				}
			},
			// 微信分享
			wxfn() {
				let _this = this;
				_this.$api.wxShare({
					url: '',
					data:{
						shareUrl: window.location.href.split('#')[0]
					},
					headers:{
						app_key: "2015042404",
						app_secret: 'F3x47g39Wc4M96nwA28T'
					}
				}).then(res => {
					wx.config({
						debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
						appId: 'wxb98dfe1831fc1e86',
						timestamp: res.timestamp,
						nonceStr: res.nonceStr,
						signature: res.signnature,
						jsApiList: [
							'checkJsApi',
							'updateAppMessageShareData',
							'updateTimelineShareData',
							'onMenuShareWeibo'
						]
					})
					let shareTitle = _this.dataSource.share_title;
					let imgURL = _this.getBGIcon(_this.dataSource.share_image);
					let desc = _this.dataSource.share_description;
					wx.ready(function () {
						wx.updateAppMessageShareData({
							title: shareTitle, // 分享标题
							desc: desc, // 分享描述
							link: window.location.href.split('#')[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
							imgUrl: imgURL, // 分享图标
							success: function () {
								// 设置成功
							}
						})
						wx.updateTimelineShareData({
							title: shareTitle, // 分享标题
							link: window.location.href.split('#')[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
							imgUrl: imgURL, // 分享图标
							success: function () {
								// 设置成功
							}
						})

						wx.onMenuShareWeibo({
							title: shareTitle, // 分享标题
							desc: desc, //
							link: window.location.href.split('#')[0], // 分享链接
							imgUrl: imgURL, // 分享图标
							type: '', // 分享类型,music、video或link，不填默认为link
							dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
							success: function () {
								// 用户确认分享后执行的回调函数
							},
							cancel: function () {
								// 用户取消分享后执行的回调函数
							}
						})
					})

					wx.error(function () {
						console.log('微信config信息验证失败')
						// alert(3);
					})
				})
			},
			toArticle(url){
				window.open(url)
			},
			getDate(str1,str2){
				let splitStr = '/';
				if (this.$root.$device == 'pc'){
					splitStr = '/';
				}else{
					splitStr = '.';
				}
				str1 = str2time(str1,splitStr);
				str2 = str2time(str2,splitStr);
				return str1.slice(0,16)+'-'+str2.slice(11,16)
			},
			getBGIcon(obj){
				if (obj== undefined || obj.original == undefined){
					return '';
				}else{
					return obj.original[0].url;
				}
			},
			getIcon(name){
				let str = '';
				if (name){
					str = require('@/assets/images/icons/'+name+'.png');
				}else{
					str = require('@/assets/logo.png');
				}
				return 'background-image:url("'+str+'")';
			},
			getLive(){
				let _this = this
				let key =  getQueryVariable('videokey')
				_this.$api.liveUrl({
					url: key,
					data:{},
				}).then((ok)=>{
					if (ok.data.stream_source =='ali' && ok.data.stream_id!==''){
						this.showPage = false;
					}
					if (ok.data.stream_source == 'url' && ok.data.stream_id!==''){
						this.showPage = false;
						window.open( ok.data.stream_id,'_self')
					}else{
						this.showPage = true;
					}
					_this.hand_num = ok.data.number_of_upvotes;
					_this.eyes_num = ok.data.number_of_reads;
					_this.dataSource = ok.data;
					let arr = [_this.getBGIcon(ok.data.main_logo),_this.getBGIcon(ok.data.vice_logo)];
					this.$store.commit("setLogoArr", arr);
					let type = '';
					let src = '';
					let img = "";
					if (ok.data.stream_source == 'polyv' && ok.data.live_status == 'living'){
						_this.show = true;
						_this.getLiveData()
					}else{
						_this.show = false;
						if (ok.data.live_status == 'live_replay'){
							type = 'video/mp4'; // mp4格式 回放;
							src = ok.data.video_replay_url;
							img = _this.getBGIcon(ok.data.video_image);
						}else if (ok.data.live_status == 'living'){
							if ( ok.data.stream_id.indexOf('rtmp')>=0){
								type = 'rtmp/mp4'
							}else{
								type = 'application/x-mpegURL';
							}
							src = ok.data.stream_id;
							img = _this.getBGIcon(ok.data.video_image);
						}else{
							type = 'video/mp4'; // mp4格式 回放;
							src = ok.data.video_replay_url;
							img = _this.getBGIcon(ok.data.video_image);
						}
						_this.video_data = {
							videoType: type,
							videoSrc: src,
							videoImg: img
						}
					}

					if (_this.dataSource.tags[0]){
						_this.getLiveInfo(_this.dataSource.tags[0].guid)
					}else{
						_this.showNone = true;
					}

				});
			},
			getLiveInfo(tags){
				let _this = this;
				_this.$api.liveTagsUrl({
					guid: tags,
					data:{},
				}).then((ok)=>{
					_this.showNone = false;
					_this.infoList = ok.data;
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.live{
		width: 100%;
		height: calc(100vh);
		overflow-y: auto;
		padding: 0px 0 69px 0;
		box-sizing: border-box;
		background-size: 100% auto;
		background-repeat: repeat-y;
		@media screen and(max-width: 1139px){
			width: 100%;
			min-height: calc(100vh - 4.8rem);
			padding: 0 0 6rem 0;
		}
		.nav{
			width: 100%;
			display: flex;
			justify-content: center;
			background: #2B2B2B;
			box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.05);
			margin-bottom: 40px;
			@media screen and(max-width: 1139px){
				box-shadow: 0px 0.6rem 1.6rem 0px rgba(0, 0, 0, 0.05);
				margin-bottom: 0;
			}
		}
		.videobox{
			width: 1140px;
			margin: 0 auto;
			@media screen and(max-width: 1139px){
				padding: 1.5rem;
				width: 100%
			}
			.titlecont{
				width: 100%;
				height: 56px;
				padding: 0 40px;
				background-color: #2B2B2B;
				@media screen and(max-width: 1139px){
					display: none;
				}
				em{
					float: left;
					width: 80%;
					@include text-ellipsis(1);
					@include fontS($size:16px,$weight:500,$lineH:56px);
					color: #FFFFFF;
				}
				time{
					width: 20%;
					float: right;
					text-align: right;
					@include fontS($size:14px,$weight:500,$lineH:56px);
					color: #999999;
				}
			}
			.video{
				width: 100%;
				height: 640px;
				outline: none;
				position: relative;
				@media screen and(max-width: 1139px){
					height: 19rem;
				}
				#ppt{
					width: 30%;
					height: 30%;
					position: absolute;
					top: 0;
					left: 0;
				}
				#player{
					width: 100%;
					height: 100%;
				}
			}
			.bottom-row{
				width: 100%;
				height: 40px;
				padding: 0 40px;
				background-color: #2B2B2B;
				@media screen and(max-width: 1139px){
					height: 2.8rem;
					padding: 0 1.5rem;
				}
				.replay{
					display: inline-flex;
					align-items: center;
					@include fontS($size:14px,$weight:400,$lineH:40px);
					color: #999999;
					@media screen and(max-width: 1139px){
						@include fontS($size:1.2rem,$weight:400,$lineH:2.8rem);
					}
					em{
						margin-left: 15px;
						display: inline-block;
						@media screen and(max-width: 1139px){
							margin-left: 1.5rem;
						}
					}
				}
				.views{
					cursor: pointer;
					float: right;
					margin-left: 20px;
					height: 40px;
					@include fontS($weight:400,$size:14px,$lineH:40px);
					color: #999999;
					display: flex;
					align-items: center;
					position: relative;
					@media screen and(max-width: 1139px){
						height: 2.8rem;
						margin-left: 2rem;
						@include fontS($weight:400,$size:1rem,$lineH:2.8rem);
					}
					&:hover{
						.icon{
							&.hand{
								background-image: url("../../assets/images/icons/hand_l.png");
							}
							&.share{
								background-image: url("../../assets/images/icons/share_l.png");
							}
						}
						.num{
							color: rgb(234, 113, 20);
						}
					}
					&.share{
						cursor: pointer;
						@media screen and(max-width: 1139px){
							display: none;
						}
						.icon{
							background-size: 22px 22px;
							@media screen and(max-width: 1139px){
								background-size: 1.6rem 1.6rem;
							}
						}
					}
					ul{
						position: absolute;
						top: 55px;
						left: -17px;
						z-index: 66;
						width: 112px;
						padding: 0 15px 10px;
						background: #2B2B2B;
						border-radius: 2px;
						box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.24);
						span{
							display: inline-block;
							width: 0;
							height: 0;
							border: 8px solid #00000000;
							border-bottom-color: #2B2B2B;;
							position: absolute;
							top: -16px;
							left: 50%;
							margin-left: -8px;
						}
						li{
							width: 100%;
							line-height: 17px;
							display:block;
							margin-top: 15px;
							em{
								@include fontS($weight:400,$size:12px,$lineH:17px);
								display: inline-block;
							}
							.icon{
								width: 17px;
								height: 16px;
								background-size: 100% 100%;
								margin-left: 5px;
								margin-right: 0;
								border-radius: 50%;
							}
							.qrcode{
								display: block;
								width: 82px;
								height: 82px;
								margin: 5px auto 0;
								background-color: white;
							}
						}
					}
					.icon{
						display: inline-block;
						width: 22px;
						height: 22px;
						background-repeat: no-repeat;
						background-position: center center;
						margin-right: 5px;
						margin-left: 0;
						@media screen and(max-width: 1139px){
							width: 1.6rem;
							height: 1.6rem;
							background-size: 1.6rem 1.6rem;
						}
						&.eyes{
							background-size: 22px 16px;
							background-image: url("../../assets/images/icons/eyes.png");
							@media screen and(max-width: 1139px){
								background-size: 1.6rem 1.2rem;
							}
						}
						&.hand{
							background-size: 18px 17px;
							background-image: url("../../assets/images/icons/hand.png");
							@media screen and(max-width: 1139px){
								background-size: 1.4rem 1.3rem;
							}
						}
						&.share{
							background-size: 18px 18px;
							background-image: url("../../assets/images/icons/share.png");
							&:hover{
								background-image: url("../../assets/images/icons/share_l.png");
							}
						}
					}
					.num{
						@include fontS($weight:400,$size:14px,$lineH:22px);
						color: rgba(255,255,255,0.8);
						@media screen and(max-width: 1139px){
							@include fontS($weight:400,$size:1rem,$lineH:2.8rem);
							color: rgba(255,255,255,0.8);
						}
					}

				}
			}
		}
		.last-row{
			width: 1140px;
			margin: 30px auto 0;
			@media screen and(max-width: 1139px){
				width: 100%;
				margin: 1.4rem auto 0;
			}
			.left{
				width: 750px;
				background: #2B2B2B;
				padding: 0 40px 30px;
				@media screen and(max-width: 1139px){
					width: 100%;
					padding: 0rem 1.5rem 1.5rem;
					background: none;
				}
				h3{
					width: 100%;
					@include fontS($size:20px,$lineH:68px,$weight:500);
					color: #FFFFFF;
					border-bottom: 1px solid rgba(255,255,255,0.1);
					@media screen and(max-width: 1139px){
						@include fontS($size:1.4rem,$lineH:3.2rem,$weight:400);
					}
					.time{
						display: none;
						@media screen and(max-width: 1139px){
							display: block;
							float: right;
						}

					}
				}
				.title_phone{
					display: none;
					@media screen and(max-width: 1139px){
						display: block;
						margin: 1.5rem 0;
						@include fontS($size:1.8rem,$lineH:2.5rem,$weight:500);
						color: #FFFFFF;
					}
				}
				.author-row{
					width: 100%;
					margin: 20px 0 30px;
					display: flex;
					align-items: center;
					@media screen and(max-width: 1139px){
						margin: 1.5rem 0 3rem;
					}
					.icon{
						width: 40px;
						height: 40px;
						border-radius: 50%;
						background-size: 100% 100%;
						margin-right: 14px;
						@media screen and(max-width: 1139px){
							width: 3rem;
							height: 3rem;
							margin-right: 1rem;;
						}
					}
					em{
						@include fontS($size:16px,$lineH:40px,$weight:500);
						color: #FFFFFF;
						@media screen and(max-width: 1139px){
							@include fontS($size:1.4rem,$lineH:3rem,$weight:400);
						}
					}
				}

			}
			.right{
				width: 360px;
				background: #2B2B2B;
				@media screen and(max-width: 1139px){
					display: none;
				}
				.border-row{
					width: 100%;
					height: 3px;
					background: #FD8A25;
				}
				h3{
					width: 100%;
					padding: 0 15px;
					@include fontS($size:16px,$lineH:44px,$weight:500);
					color: #FFFFFF;
					border-bottom: 1px solid rgba(255,255,255,0.1);

				}
				.listbox{
					width: 100%;
					padding-left: 15px;
					.shownone{
						text-align: center;
						@include fontS($size:14px,$lineH:20px,$weight:400);
						color: #999999;
					}
					li{
						cursor: pointer;
						width: 100%;
						padding: 15px;
						border-top: 1px solid rgba(238, 238, 238, 0.1);
						position: relative;
						&:first-child{
							border: none;
						}
						img{
							width: 100px;
							height: 80px;
							margin-right: 15px;
						}
						div{
							width: calc(100% - 115px);
							height: 80px;
							position: relative;
							.title-cont{
								@include fontS($size:14px,$lineH:20px,$weight:400);
								@include text-ellipsis();
								color: #FFFFFF;
								margin-bottom: 23px;
							}
							span{
								@include fontS($size:12px,$lineH:17px,$weight:400);
								color: rgba(255,255,255,0.6);
								position: absolute;
								bottom: 0;
								left: 0;
							}
						}
					}
				}
			}
		}

	}
</style>
<style lang="scss">
	.com-row{
		width: 100%;
		height: auto;
		@include fontS($size:16px,$lineH:22px,$weight:400);
		color: rgba(255,255,255,0.8);
	}
	.com-row p,.com-row ol,.com-row ul,.com-row li{
		margin: 30px 0;
		@media screen and(max-width: 1039px){
			margin: 1.6rem 0;
		}
	}
	.com-row img{
		margin: 30px 0;
		@media screen and(max-width: 1039px){
			margin: 3rem 0;
		}
	}
</style>
