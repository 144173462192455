<template>
	<div class="video-index">
		<video-player class="video-player vjs-custom-skin"
									ref="videoPlayer"
									:playsinline="true"
                  :options="playerOptions"
									v-if="videoData.videoSrc && videoData.videoSrc !=='' "
		></video-player>
		<div v-else>
			<img :src="videoData.videoImg" alt="">
		</div>
	</div>
<!--	// replay_image-->
</template>

<script>

	export default {
		name: "videoBox",
		data(){
			return{
				playerOptions: {},
				type:'',
				src:'',
				img:'',
			}
		},
		props:{
			videoData:{
				type: Object,
				default: ()=>{
					return {}
				},
				required: true
			}
		},
		watch:{

			videoData(val){
				this.setVideo(val);
				return val
			},
		},
		created() {
			this.setVideo(this.videoData);
		},
		methods:{
			setVideo(obj){
        console.log(obj.videoType)
        console.log(obj.videoSrc)
				let _this = this;
				_this.playerOptions = {
					playbackRates : [ 0.5, 1.0, 1.5, 2.0 ], //可选择的播放速度
					autoplay : true, //如果true,浏览器准备好时开始回放。
					muted : false, // 默认情况下将会消除任何音频。
					loop : false, // 视频一结束就重新开始。
					preload : 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language : 'zh-CN',
					aspectRatio : '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid : false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					techOrder: ['html5', 'flash'], // 兼容顺序
					flash: {
            html5: { hls: { withCredentials: false } },
            hls: {
							withCredentials: false
						},
          },
					sources : [{
						type: obj.videoType,
						src: obj.videoSrc,

					}],
					poster: obj.videoImg, //你的封面地址
					// width: document.documentElement.clientWidth,
					notSupportedMessage : '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar : {
						timeDivider : true,// 当前时间和持续时间的分隔符
						currentTimeDisplay: true, // 当前时间
						durationDisplay : true,// 持续时间
						remainingTimeDisplay : false,// 是否显示剩余时间功能
						progressControl: true, // 进度条
						volumeControl: false, // 声音控制键
						playToggle: true, // 暂停和播放键
						fullscreenToggle : true,  // 全屏按钮
					}
				}
			}
		},

	}
</script>

<style lang="scss">
.video-index{
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: #2B2B2B;
}
.video-index div{
	width: 100%;
	height: 100%;

}
img{
	display: block;
	width: 100%;
	height: 100%;
}
/*播放按钮设置成宽高一致，圆形，居中*/
.vjs-custom-skin > .video-js .vjs-big-play-button {
	background-color: rgba(0,0,0,0);
	border: 0;
	border-radius: 50%;
	height: 110px !important;
	margin-top: -55px !important;
	margin-left: -55px !important;
	width: 110px !important;
	background-image: url("../assets/images/icons/play.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center center;
	@media screen and(max-width: 1139px){
		height: 3.4rem !important;
		margin-top: -1.7rem !important;
		margin-left: -1.7rem !important;
		width: 3.4rem !important;
	}
}
.vjs-big-play-button .vjs-icon-placeholder{
	display: none;
}
.vjs-big-play-button span.vjs-control-text{

}
.vjs-poster{
	background-size: cover;
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
}

/*control-bar布局时flex，通过order调整剩余时间的位置到进度条右边*/
.vjs-custom-skin > .video-js .vjs-control-bar .vjs-remaining-time{
	order:3 !important;
}

/*进度条背景轨道*/
.video-js .vjs-slider{
	border-radius: 1em;
}

/*进度条进度*/
.vjs-custom-skin > .video-js .vjs-play-progress, .vjs-custom-skin > .video-js .vjs-volume-level{
	border-radius: 1em;
}

/*鼠标进入播放器后，播放按钮颜色会变*/
.video-js:hover .vjs-big-play-button, .vjs-custom-skin>.video-js .vjs-big-play-button:active, .vjs-custom-skin>.video-js .vjs-big-play-button:focus{
	background-color: rgba(0,0,0,0.4) !important;
}

/*control bar*/
.video-js .vjs-control-bar{
	background-color: rgba(0,0,0,0.2) !important;
}

/*点击按钮时不显示蓝色边框*/
.video-js .vjs-control-bar button{
	outline: none;
}
.video-js .vjs-time-control{
	line-height: 2em;
}
</style>
