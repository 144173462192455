<template>
	<div class="countdown">
		<span v-if="day && day != 0">
			{{day}}
			<em>天</em>
		</span>
		<span v-if="hour && hour != '00'">
			{{hour}}
			<em>小时</em>
		</span>
		<span v-if="min && min !='00' ">
			{{min}}
			<em>分</em>
		</span>
		<span v-if="sec">
			{{sec}}
			<em>秒</em>
		</span>
	</div>
</template>

<script>
	export default {
		name: "countdown",
		props:{
			startTime:{
				type: String,
				default: ''
			}
		},
		watch:{
			startTime(val){
				return val;
			}
		},
		data(){
			return{
				day: null,
				hour: null,
				min: null,
				sec: null,
			}
		},
		created() {
			this.$nextTick(function () {

				this.countDown();
			})
		},
		methods:{
			countDown(){
				let date = new Date();
				let now = date.getTime();
				// 设置截止时间
				let endDate = new Date(this.startTime*1000) // this.curStartTime需要倒计时的日期
				let end = endDate.getTime();
				// 时间差
				let leftTime = end - now
				// 定义变量 d,h,m,s保存倒计时的时间
				if (leftTime >= 0) {
					// 天
					this.day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
					// 时
					let h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
					this.hour = h < 10 ? '0' + h : h
					// 分
					let m = Math.floor(leftTime / 1000 / 60 % 60)
					this.min = m < 10 ? '0' + m : m
					// 秒
					let s = Math.floor(leftTime / 1000 % 60)
					this.sec = s < 10 ? '0' + s : s
				} else {
					this.day = 0
					this.hour = '00'
					this.min = '00'
					this.sec = '00'
				}
				// 等于0的时候不调用
				if (Number(this.hour) === 0 && Number(this.day) === 0 && Number(this.min) === 0 && Number(this.sec) === 0) {
					return
				} else {
					setTimeout(this.countDown, 1000)
				}
			}

		}
	}
</script>

<style lang="scss" scoped>
	.countdown{
		display: flex;
		align-items: center;
		span{

			display: inline-flex;
			align-items: center;
			@include fontS($size:16px,$weight:500,$lineH:16px);
			color: #FD8A25;
			@media screen and(max-width: 1139px){
				@include fontS($size:1.2rem,$weight:500,$lineH:1.2rem);
			}
			em{
				color: #FFFFFF;
			}
		}
	}
</style>
