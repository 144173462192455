<template>
	<div class="live_popup">
		<div>
			<button class="close" @click="hidePopupFn"></button>
			<h3>区块链技术、服务等高价值内容，尽在「链得得Pro直播」。</h3>
			<ul>
				<li>
					<span class="question">01.为什么听不到视频声音？</span>
					<em>• 可能你的设备正处于静音模式哦！</em>
					<em>• 请将设备设置为非静音模式，建议佩戴耳机，收听效果更佳。</em>
				</li>
				<li>
					<span class="question">02.视频卡顿怎么解决？</span>
					<em>• 建议在WIFI环境下收看视频，以保证收看效果并节省流量。</em>
					<em>• 刷新页面，重新加载一下视频。</em>
					<em>• 退出重新进入视频收看页面。</em>
				</li>
				<li>
					<span class="question">03.直播时为什么会有延时现象？</span>
					<em>• 受制于不同的网络环境，直播期间有10秒左右的延时属于正常现象。</em>
					<em>• 如果您很介意，建议更换到更稳定的WIFI网络环境中收看视频。</em>
				</li>
				<li>
					<span class="question">04.没赶上视频直播怎么办？</span>
					<em>• 「链得得Pro直播」提供回放功能，如果您因为时间的关系没有赶上直播，您可以查看视频回放。</em>
				</li>
				<li>
					<span class="question">05.怎样获取更多的「链得得Pro直播」信息？</span>
					<em>• 关注链得得微信公众号 ChainDD</em>
					<img src="../../../public/appsCode2.png" alt="">
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: "live_popup",
		methods:{
			hidePopupFn(){
				this.$parent.showPopupFn(false);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.live_popup{
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.9);
		padding-top: 104px;
		padding-bottom: 130px;
		overflow-y:scroll;
		@media screen and(max-width: 1139px){
			padding-top: 5.7rem;
			padding-bottom: 5.7rem;
		}
		div{
			width: 620px;
			margin: 0 auto;
			position: relative;
			@media screen and(max-width: 1139px){
				width: 100%;
				padding: 0 1.5rem;
			}
			.close{
				width: 20px;
				height: 20px;
				background-image: url("../../assets/images/icons/close.png");
				background-size: 100% 100% ;
				position: absolute;
				top: -37px;
				right: 0;
				@media screen and(max-width: 1139px){
					width: 2rem;
					height: 2rem;
					top: -4.3rem;
					right: 1.5rem;
				}
			}
			h3{
				@include fontS($size:18px,$lineH:25px,$weight:500);
				color: #FFFFFF;
				@media screen and(max-width: 1139px){
					@include fontS($size:1.8rem,$lineH:2.5rem,$weight:500);
				}
			}
			ul{
				width: 100%;
				li{
					width: 100%;
					margin-top: 30px;
					@media screen and(max-width: 1139px){
						margin-top: 3rem;
					}
					span {
						display: block;
						@include fontS($size: 18px, $lineH: 25px, $weight: 500);
						color: #FFFFFF;
						margin-bottom: 10px;
						@media screen and(max-width: 1139px) {
							@include fontS($size: 1.8rem, $lineH: 2.5rem, $weight: 500);
							margin-bottom: 1.5rem;
						}
					}
					em{
						display: block;
						@include fontS($size:14px,$lineH:20px,$weight:400);
						color: rgba(255,255,255,0.8);
						@media screen and(max-width: 1139px) {
							@include fontS($size: 1.4rem, $lineH: 2.0rem, $weight: 500);
							margin-bottom: 1rem;
						}
					}
					img{
						display: block;
						width: 166px;
						height: 166px;
						background-color: #FF2A2A;
						margin-top: 10px;
					}
				}
			}
		}
	}
</style>
