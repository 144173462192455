<template>
	<div class="navRow clearfix">
		<img v-for="(item,index) in logos" :key="index" class="fl" :src="item == ''?logoSrcArr[index]:item" alt="">
		<a class="fr" target="_blank" href="https://www.chaindd.net/">
			返回链得得
			<span></span>
		</a>
		<span class="icon fr" @click="showPopupFn(true)"></span>
		<div class="popup" v-show="showPopup">
			<live-popup />
		</div>
	</div>
</template>

<script>
	import livePopup from '@/components/popuplayer/live_popup'
	export default {
		name: "navRow",
		components:{
			livePopup
		},
		data(){
			return{
				showPopup:false,
				logos:[],
				logoSrcArr:[ require('../../../public/chaindd-logo.png'),require('../../../public/pro-logo.png')]
			}
		},
		computed:{
			logoArr(){
				let _this = this;
				_this.logos = _this.$store.state.logoArr;
				return this.$store.state.logoArr;
			}
		},
		watch:{
			logoArr(val){
				this.logos = val;
				return val
			}
		},
		methods:{
			showPopupFn(boolean){
				this.showPopup = boolean;
				if (boolean){
					document.body.style.overflowY = 'hidden'
				}else{
					document.body.style.overflowY = 'auto'
				}

			}
		}
	}
</script>
<style lang="scss" scoped>
.navRow{
	width: 1140px;
	height: 48px;
	padding-top: 12px;
	@media screen and(max-width: 1139px){
		width: 100%;
		height: 4.4rem;
		background: #2B2B2B;
		box-shadow: 0px 0.6rem 1.6rem 0px rgba(0, 0, 0, 0.05);
		padding: 1.2rem 1.5rem 0;
	}
	img{
		display: block;
		height: 22px;
		width: auto;
		margin-right: 20px;
		@media screen and(max-width: 1139px){
			height: 1.6rem;
		}
	}
	a{
		display: inline-flex;
		align-items: center;
		@include fontS($size:16px,$weight:400,$lineH:22px);
		color: #FFFFFF;
		span{
			display: inline-block;
			width: 8px;
			height: 22px;
			background-image: url("../../assets/images/icons/left.png");
			background-size: 100% auto;
			background-position: center center;
			background-repeat: no-repeat;
			margin-left: 8px;
		}
		@media screen and(max-width: 1139px){
			display: none;
		}
	}
	.icon{
		display: inline-block;
		width: 20px;
		height: 22px;
		margin-right: 30px;
		background-image: url("../../assets/images/info-circle@2x.png");
		background-size: 100% auto;
		background-position: center center;
		background-repeat: no-repeat;
		@media screen and(max-width: 1139px){
			width: 2rem;
			height: 2rem;
			margin-right: 0;
		}
	}
	.popup{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
	}
}
</style>
